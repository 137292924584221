import React, { useRef, useState, useEffect } from "react";
import "./Home.css";
import ComingVideoWeb from "../../Assets/Video/Coming-Web.mp4";
import ComingVideoMobile from "../../Assets/Video/Coming-Mobile.mp4";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

export default function HomeContent() {
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0];

      if (player) {
        player.controls = false;
        player.playsInline = true;
        player.muted = true;
        player.setAttribute("muted", "");
        player.autoplay = true;

        setTimeout(() => {
          const promise = player.play();
          if (promise && promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, [isMobile]);

  const videoSrc = isMobile ? ComingVideoMobile : ComingVideoWeb;

  return shouldUseImage ? (
    <img src={videoSrc} alt="Video" />
  ) : (
    <div className="Home" ref={videoParentRef}>
      <div
        dangerouslySetInnerHTML={{
          __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            preload="metadata"
            class="video-background"
          >
            <source src="${videoSrc}" type="video/mp4" />
          </video>`,
        }}
      />
    </div>
  );
}
